import React, { useEffect, useState } from "react"
import { Link } from 'gatsby'
import logo from "../../assets/ulaLogo.webp";
import { BsFillTelephoneFill } from "react-icons/bs"
import { GrMail } from "react-icons/gr"
import { FaFacebookF } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io"

import "./nav.scss"



const Nav = ({ }) => {

    //const [openMobileMenu, setOpenMobileMenu] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    //const listenScrollEvent = (e) => setIsScrolled(window.scrollY > 0);

    useEffect(() => {

        const listenScrollEvent = (e) => setIsScrolled(window.scrollY > 0);

        window.addEventListener("scroll", listenScrollEvent);

        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };

    }, [])


    return (
        <div className="sticky-nav">

            <div className="top-nav">
                <div className="navbar-top">
                    <div>
                        <ul className="social-links">
                            <li>
                                <a href="tel:01704468030" className="social-page"><BsFillTelephoneFill style={{ marginRight: "10px" }} /> 01704 468 030</a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/unitedlegalassistance/" className="social-page" style={{ paddingLeft: "10px" }} aria-label="Facebook"><FaFacebookF /></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/united_legal" className="social-page" style={{ paddingLeft: "10px" }} aria-label="XTwitter"><FaXTwitter /></a>
                            </li>
                        </ul>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        <ul className="btns">
                            <li className="call-btn">
                                <a href="/cold-call-warning" className="">Cold Call Warning</a>
                            </li>
                            <li className="claim-btn">
                                <a href="/broker/Claims/Index/ULA1" className="">START A CLAIM NOW</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <nav className="uk-navbar-container" uk-navbar="true">

                <div className="uk-navbar-left navbar-left">
                    <Link to="/" aria-label="ULA Logo">
                        <img src={logo} className="transition-logo" alt="" width="150" />
                    </Link>
                </div>

                <div className="uk-navbar-right navbar-right uk-visible@l">
                    <div>
                        <ul className="uk-navbar-nav menu">
                            <li>
                                <a href="/about" className="menu-link">ABOUT <IoIosArrowDown /></a>
                                <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown="offset: 18">
                                    <div className="submenu-container__display-flex">
                                        <a href="/covid-19-statement">COVID-19 STATEMENT</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="/services" className="menu-link">SERVICES <IoIosArrowDown /></a>
                                <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown="offset: 18">
                                    <div className="submenu-container__display-flex">
                                        <a href="/uninsured-loss-recovery">UNINSURED LOSS RECOVERY</a>
                                        <a href="/credit-hire-and-credit-repair">CREDIT HIRE & CREDIT REPAIR</a>
                                        <a href="/third-party-intervention">THIRD-PARTY INTERVENTION</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="/products" className="menu-link">PRODUCTS <IoIosArrowDown /></a>
                                <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown="offset: 18">
                                    <div className="submenu-container__display-flex">
                                        <a href="/motor-legal-expenses">MOTOR LEGAL EXPENSES</a>
                                        <a href="/guaranteed-replacement-vehicle-insurance">GUARANTEED REPLACEMENT VEHICLE INSURANCE</a>
                                        <a href="/additional-products">ADDITIONAL PRODUCTS</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="/blog" className="menu-link">BLOG</a>
                                {/* <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown={isScrolled ? "offset: 13" : "offset: 44"}>
                                    <div className="submenu-container__display-flex">
                                        <a href="/what-is-motor-legal-protection">What is motor legal protection?</a>
                                    </div>
                                </div> */}
                            </li>
                            <li>
                                <a href="/contact-us" className="menu-link">CONTACT</a>
                            </li>
                            <li>
                                <a href="/broker" className="menu-link">LOGIN</a>
                            </li>
                        </ul>
                    </div>
                </div>




                <div className="uk-navbar-right uk-hidden@l">
                    <a
                        href=""
                        className="uk-navbar-toggle menuMobileBtn" data-uk-toggle="target: #mobile-width-menu"
                        //onClick={() => setOpenMobileMenu(!openMobileMenu)}
                        aria-label="Open Menu"
                    >
                        <span data-uk-navbar-toggle-icon>&nbsp;</span>
                    </a>
                </div>




                <div id="mobile-width-menu" hidden>
                    <div className="uk-navbar">
                        <div className="uk-navbar-dropdown uk-flex uk-flex-column mobile-menu-container">
                            <ul className="uk-nav uk-navbar-dropdown-nav">
                                <li>
                                    <a href="/about" className="bg_color">About</a>
                                    <ul className="submenu">
                                        <li><a href="/covid-19-statement">Covid-19 Statement</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="/services" className="bg_color">Services</a>
                                    <ul className="submenu">
                                        <li><a href="/uninsured-loss-recovery">Uninsured Loss Recovery</a></li>
                                        <li><a href="/credit-hire-and-credit-repair">Credit Hire & Credit Repair</a></li>
                                        <li><a href="/third-party-intervention">Third-Party Intervention</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="/products" className="bg_color">Products</a>
                                    <ul className="submenu">
                                        <li><a href="/motor-legal-expenses">Motor Legal Expenses</a></li>
                                        <li><a href="/guaranteed-replacement-vehicle-insurance">Guaranteed Replacement Vehicle Insurance</a></li>
                                        <li><a href="/additional-products">Additional Products</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="/blog">Blog</a>
                                </li>
                                <li>
                                    <a href="/contact-us">Contact</a>
                                </li>
                                <li>
                                    <a href="/broker">Login</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </nav >
        </div >

    )
}

export default Nav