import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import "../../styles/variables.scss"


function Seo({ description, lang, meta, title }) {
  
  // console.log("seo title: ", strapiSiteSettings)
  // console.log("page-title: ", title)
  const metaDescription = description || "metaDescription"
  var metaTitle = title || "metaTitle"
  var titleTemplate = "metaTitle"



  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={titleTemplate}
      link={[
        {
          rel: "preconnect",
          href:
            "https://fonts.gstatic.com",
        },
        {
          rel: "preconnect",
          href:
            "https://cdn.jsdelivr.net",
        },
        {
          rel: "preconnect",
          href:
            "https://fonts.googleapis.com/",
        },
        {
          rel: "preload",
          href: "https://fonts.googleapis.com/css?family=Roboto",
          as: "style"
        },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css?family=Roboto",
          // async: true
        },
        // {
        //   rel: "stylesheet",
        //   href:
        //     "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/css/uikit.min.css",
        // }

      ]}
      script={[
        // {
        //   src:
        //     "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/js/uikit.min.js",
        // },
        // {
        //   src:
        //     "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/js/uikit-icons.min.js",
        // }
      ]}


    >
      <meta key="key-description" name="description" content={metaDescription} />
      <meta key="key-ogtitle" name="og:title" content={metaTitle} />
      <meta key="key-ogdescription" name="og:description" content={metaDescription} />
      <meta key="key-ogtype" name="og:type" content="website" />
      <meta key="key-description" name="twitter:card" content="summary" />
      <meta key="key-twittercreateor" name="twitter:creator" content={"author"} />
      <meta key="key-twittertitle" name="twitter:title" content={metaTitle} />
      <meta key="key-twitterdesc" name="twitter:description" content={metaDescription} />
      
      {/* meta tags */}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  title: ''
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
