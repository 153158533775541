import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./footer.scss"
import { FaFacebookF } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";

const Footer = ({ disclaimer }) => {


    return (
        <>
            <section className="footer-section">
                <div className="footer-wrapper">
                    <div className="footer-content">

                        <div className="first-column">
                            <div>
                                <ul>
                                    <li>
                                        <a href="/"><IoIosArrowForward /> Home</a>
                                    </li>
                                    <li>
                                        <a href="/about"><IoIosArrowForward /> About</a>
                                    </li>
                                    <li>
                                        <a href="/services"><IoIosArrowForward /> Services</a>
                                    </li>
                                    <li>
                                        <a href="/products"><IoIosArrowForward /> Products</a>
                                    </li>
                                    <li>
                                        <a href="/hospitality"><IoIosArrowForward /> Hospitality</a>
                                    </li>
                                    <li>
                                        <a href="/blog"><IoIosArrowForward /> Blog</a>
                                    </li>
                                    <li>
                                        <a href="/contact-us"><IoIosArrowForward /> Contact Us</a>
                                    </li>
                                </ul>
                                <div>
                                    <ul>
                                        <li>
                                            <a href="/complaints-handling-procedure">Complaints Handling Procedure </a>
                                        </li>
                                        <li>
                                            <a href="/privacy-policy">Privacy Policy</a> <a href="/cookie-policy">Cookie Policy</a>
                                        </li>
                                        <li>
                                            <p>Copyright @ {new Date().getFullYear()} <a href="/">United Legal Assistance</a></p>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div className="second-column">
                            <p className="footer-subtitle">Regulations</p>
                            <span>
                                <p><a href="/">United Legal Assistance</a> is registered in England at 130C Lord Street, Southport, PR9 0AE.
                                Company Number: 08934259. Authorised and regulated by the Financial Conduct Authority. Firm number: 629948. This can be checked by visiting
                                their website. Telephone calls may be recorded.</p>
                            </span>
                        </div>

                        <div className="third-column">
                            <p className="footer-subtitle">FOLLOW</p>
                            <span className="footer-social">
                                <a href="https://www.facebook.com/unitedlegalassistance/" aria-label="Facebook Page"><FaFacebookF fontSize="1.5rem" /></a>
                                <a href="https://twitter.com/united_legal" aria-label="Twitter Page"><FaXTwitter fontSize="1.5rem" /></a>
                                <a href="https://www.linkedin.com/company/united-legal-assistance" aria-label="Twitter Page"><FaLinkedinIn fontSize="1.5rem" /></a>
                            </span>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer